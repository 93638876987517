import { Select as CoreSelectBase, Form } from 'core-ui';
import { SelectPropsI } from 'core-ui/lib/components/Select/Select';

type CoreSelectProps = SelectPropsI & {
  formItemClassName?: string;
  name?: (string | number)[] | string;
  label?: string;
  rules?: any[];
  mode?: string;
  disabled?: boolean;
  placeholder?: string;
};

const CoreSelect: React.FC<CoreSelectProps> = ({
  placeholder,
  formItemClassName,
  name,
  label,
  rules,
  mode,
  id,
  options,
  defaultValue,
  disabled,
  onChange
}) => {
  return (
    <>
      <Form.Item
        className={formItemClassName}
        name={name}
        label={label}
        rules={rules}
      >
        <CoreSelectBase
          mode={mode}
          showSearch
          notFoundContent="データがありません。"
          className="w-full"
          id={id}
          options={options}
          defaultValue={defaultValue}
          disabled={!!disabled}
          placeholder={placeholder}
          allowClear
          onChange={(value) => {
            if (onChange) {
              onChange(value, []);
            }
          }}
          optionFilterProp="label"
          filterSort={(optionA: any, optionB: any) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
        />
      </Form.Item>
    </>
  );
};

export default CoreSelect;
