import { RADIO_COLOR } from 'src/constants/common';

const ContentEventCustom = ({ event }: any) => {
  let colorCustom = RADIO_COLOR.find(
    (item: { value?: number }) => item.value === Number(event?.color ?? 1)
  )?.color;

  const title = event?.title;

  return (
    <>
      <div
        style={{ color: colorCustom }}
        className={`text-xs font-normal !text-[${colorCustom}] relative`}
      >
        {`${title}`}
      </div>
    </>
  );
};
export default ContentEventCustom;
