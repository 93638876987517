import { Flex, Spin } from 'core-ui';

const LoadingSpin = () => {
  return (
    <>
      <div className="flex justify-center items-center h-[90vh]">
        <Flex>
          <Spin size={'large'} />
        </Flex>
      </div>
    </>
  );
};
export default LoadingSpin;
