import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IconHiddenBottom } from 'src/assets/icons/IconHiddenBottom';
import { IconHiddenTop } from 'src/assets/icons/IconHiddenTop';
import { RoutePath } from 'src/enums/routePath';
import { tabSchedule } from 'src/pages/AdminManagement/Schedule';
import { RootState } from 'src/redux/store';
import { widthHeightState } from 'src/redux/widthHeightSlice';

interface ButtonHiddenSearchProps {
  setIsHiddenSearch?: (isHidden: boolean) => void;
  isHiddenSearch?: boolean;
}
const ButtonHiddenSearch: React.FC<ButtonHiddenSearchProps> = ({
  setIsHiddenSearch,
  isHiddenSearch = false
}) => {
  const resize: any = useSelector((state: widthHeightState) => state?.resize);

  const location = useLocation();

  const { currentTabSchedule } = useSelector(
    (state: RootState) => state.schedule
  );

  const widthResize = resize?.resize?.width;

  const isEnable = !currentTabSchedule?.includes(tabSchedule.All);

  const isAdminPayment = location?.pathname?.includes(RoutePath.ADMIN_PAYMENT);

  const isAdminSchedule = location?.pathname?.includes(
    RoutePath.ADMIN_SCHEDULE
  );

  const isMasterAdmin = location?.pathname?.includes(RoutePath.MASTER_ADMIN);
  return (
    <div>
      {widthResize < 768 && (
        <div>
          {(isEnable ||
            (!isAdminPayment && !isAdminSchedule && !isMasterAdmin)) && (
            <hr className="text-[#f0f0f0]" />
          )}
          <div className="flex justify-end items-center gap-2 my-3 max-md:px-5">
            <span className="font-semibold text-sm">検索条件</span>
            <div
              onClick={() => {
                setIsHiddenSearch && setIsHiddenSearch(!isHiddenSearch);
              }}
            >
              {isHiddenSearch ? <IconHiddenTop /> : <IconHiddenBottom />}
            </div>
          </div>
          {!isAdminPayment ||
            (!isMasterAdmin && <hr className="text-[#f0f0f0]" />)}
        </div>
      )}
    </div>
  );
};
export default ButtonHiddenSearch;
