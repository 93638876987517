import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import LoadingProvider from './contexts/loadingContext';
import { ConfigProvider, ja_JP } from 'core-ui';
import { useEffect } from 'react';
import widthHeightSlice from './redux/widthHeightSlice';

const queryClient = new QueryClient();

const App = () => {
  const { setResize } = widthHeightSlice.actions;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        store.dispatch(
          setResize({ width: window.innerWidth, height: window.innerHeight })
        );
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <LoadingProvider>
            <ModalProvider>
              <DrawerProvider>
                <ConfigProvider locale={ja_JP}>
                  <AppRouter />
                </ConfigProvider>
              </DrawerProvider>
            </ModalProvider>
          </LoadingProvider>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
