import clsx from 'clsx';
import { Button, PlusOutlined } from 'core-ui';
import { ACTION } from 'src/constants/textAction';

interface ButtonSearchProps {
  className?: string;
}
const ButtonSearch: React.FC<ButtonSearchProps> = ({ className }) => {
  return (
    <>
      <div
        className={clsx(
          'w-full max-md:flex max-md:justify-end items-center',
          className
        )}
      >
        <Button
          className="h-[38px] hover:!bg-[#4759ff] mt-1 max-md:mb-2 bg-[#4759ff] rounded-sm w-[83px]"
          type="primary"
          htmlType="submit"
        >
          {ACTION.SEARCH}
        </Button>
      </div>
    </>
  );
};
export default ButtonSearch;
