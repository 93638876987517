export const IconHeader = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9991 0C12.3665 0.000385833 9.78025 0.693535 7.50033 2.00981C5.22041 3.32609 3.32706 5.21913 2.01051 7.49876C0.693962 9.77839 0.000571025 12.3643 3.52539e-07 14.9967C-0.00057032 17.6292 0.6917 20.2154 2.00726 22.4956C3.32282 24.7758 5.21534 26.6697 7.4947 27.9869C9.77404 29.3042 12.3599 29.9985 14.9926 30C17.6253 30.0015 20.212 29.3103 22.4929 27.9956C24.7737 26.681 26.6685 24.7894 27.9867 22.5107C29.3188 20.2337 30.0141 17.6405 29.9998 15.0026C30.0002 13.0326 29.6125 11.0818 28.8589 9.26159C28.1052 7.44141 27.0003 5.78751 25.6073 4.39435C24.2143 3.00119 22.5605 1.89606 20.7404 1.14207C18.9202 0.388077 16.9693 -4.5854e-08 14.9991 0ZM5.82679 23.2388L7.45412 14.0602C8.03152 10.5696 9.86922 7.70485 13.0119 6.76181L11.38 15.9631C10.7004 19.5385 8.92544 22.2751 5.82679 23.2388ZM11.0266 25.4964L13.4779 11.767C14.0553 8.27508 15.893 5.41294 19.0351 4.46861L16.5798 18.2207C15.9015 21.7961 14.1259 24.532 11.0266 25.4964ZM22.6011 15.9631C21.9227 19.5385 20.1471 22.2751 17.0478 23.2388L18.6758 14.0602C19.2532 10.5696 21.0896 7.70485 24.2323 6.76181L22.6011 15.9631Z"
        fill="white"
      />
    </svg>
  );
};
