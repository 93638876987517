import { IconHeader } from 'src/assets/icons/IconHeader';

const Logo = () => {
  return (
    <div className="flex items-center flex-nowrap overflow-x-hidden gap-x-[10px] text-[20px] justify-center h-[100%]">
      <IconHeader />
      <p className="text-lg font-bold">瑞穂サービスシス テム</p>
    </div>
  );
};

export default Logo;
