import clsx from 'clsx';
import { Button, LeftOutlined, RightOutlined } from 'core-ui';
import { viewMonth } from 'src/constants/date';
import {
  Views,
  View,
  Navigate,
  ToolbarProps,
  NavigateAction
} from 'core-calendar';
import { useSelector } from 'react-redux';
import scheduleSlice from 'src/redux/scheduleSlice';
import { activeViews, tabSchedule } from '..';
import { useAppDispatch } from 'src/redux/hooks';
import { RootState } from '../ScheduleDay/CustomTimeLine';
import { widthHeightState } from 'src/redux/widthHeightSlice';

type listViewsType = {
  label: string;
  view?: any;
  typeView: string;
};

const listViews = [
  { label: '日', view: Views.DAY, typeView: 'day' },
  { label: '週', view: Views.WEEK, typeView: 'week' },
  { label: '月', view: Views.MONTH, typeView: 'month' }
];

const CustomToolbar = (toolbar: ToolbarProps) => {
  const actionView = useSelector(
    (state: { schedule: { actionView: string } }) => state?.schedule?.actionView
  );

  const resize: any = useSelector((state: widthHeightState) => state?.resize);

  const widthResize = resize?.resize?.width;

  const { currentTabSchedule } = useSelector(
    (state: RootState) => state.schedule
  );

  const dispatch = useAppDispatch();

  const { setActionView, setStatusActionTabView } = scheduleSlice.actions;

  const onNavigate = (navigate: NavigateAction) => {
    toolbar.onNavigate(navigate);
  };

  const onView = (view: View) => {
    toolbar.onView(view);
  };

  const displayHr =
    currentTabSchedule?.includes(tabSchedule.Vehicle) && widthResize < 768;

  return (
    <>
      {displayHr && <hr className="bg-[#f0f5f5] mb-3 h-1 !text-[#f0f5f5]" />}
      <div className="mx-2 mb-3 flex items-center xs:justify-between max-xs:gap-4">
        <div className="flex gap-3">
          <div
            className="bg-[#DDDDDD] rounded w-9 !h-9 flex items-center justify-center cursor-pointer"
            onClick={() => {
              onNavigate(Navigate.PREVIOUS);
            }}
          >
            <LeftOutlined />
          </div>
          <div
            className="bg-[#DDDDDD] rounded w-9 !h-9 flex items-center justify-center cursor-pointer"
            onClick={() => {
              onNavigate(Navigate.NEXT);
            }}
          >
            <RightOutlined />
          </div>
          <Button
            type="primary"
            className="!bg-[#4759FF] text-[#FFFFFF] h-9 !text-white cursor-pointer w-[45px]"
            onClick={() => {
              dispatch(setActionView(activeViews.Day));
              dispatch(setStatusActionTabView(false));
            }}
          >
            今日
          </Button>
        </div>
        <div className="text-base font-semibold">
          {actionView.includes('month')
            ? viewMonth(toolbar.label)
            : toolbar.label}
        </div>
        <div className="flex gap-2 max-xs:hidden">
          {listViews.map((element: listViewsType) => (
            <div
              className={clsx(
                `bg-[#DDDDDD] !text-white rounded w-[42px] !h-9 flex items-center justify-center cursor-pointer`,
                {
                  '!bg-[#4759FF]': actionView.includes(element.typeView)
                }
              )}
              onClick={() => {
                onView(element.view);
                dispatch(setActionView(element.typeView));
                dispatch(setStatusActionTabView(false));
              }}
            >
              {element?.label}
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-2 mx-2 mb-2 xs:hidden">
        {listViews.map((element: listViewsType) => (
          <div
            className={clsx(
              `bg-[#DDDDDD] !text-white rounded w-[42px] !h-9 flex items-center justify-center cursor-pointer`,
              {
                '!bg-[#4759FF]': actionView.includes(element.typeView)
              }
            )}
            onClick={() => {
              onView(element.view);
              dispatch(setActionView(element.typeView));
              dispatch(setStatusActionTabView(false));
            }}
          >
            {element?.label}
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomToolbar;
