import { DownOutlined, Popover } from 'core-ui';
import Cookies from 'js-cookie';
import { RoutePath } from 'src/enums/routePath';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import { IUser } from 'src/models/user';
import { useSelector } from 'react-redux';
import { AuthState } from 'src/redux/authInfoSlice';
import Logo from './Logo';
import { IconHidden } from 'src/assets/icons/IconHidden';
import { useState } from 'react';
import MenuMobile from './menuMobile';

const Header = () => {
  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  const showDrawerMenuMobile = () => {
    setOpenMenuMobile(true);
  };

  const onCloseMenuMobile = () => {
    setOpenMenuMobile(false);
  };

  const handleLogout = () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
    window.location.replace(RoutePath.LOGIN);
  };

  const auth: IUser | any = useSelector(
    (state: AuthState) => state?.auth?.user
  );

  return (
    <>
      <div className="h-[100%] flex items-center md:justify-end max-md:justify-between px-[20px]">
        <div className="flex items-center max-md:hidden">
          <Popover
            placement="bottomRight"
            content={
              <div className="min-w-[100px]">
                <p
                  onClick={handleLogout}
                  className="cursor-pointer text-[red] w-full"
                >
                  ログアウト
                </p>
              </div>
            }
          >
            <div className="flex cursor-pointer items-center">
              <div className="flex flex-col">
                <p className="leading-[20px] font-medium text-white text-[14px] min-w-[50px]">
                  {auth?.name}
                </p>
              </div>
              <DownOutlined
                style={{
                  color: 'white',
                  marginLeft: '12px'
                }}
              />
            </div>
          </Popover>
        </div>
        <div className="md:hidden">
          <Logo />
        </div>
        <div className="md:hidden w-fit" onClick={showDrawerMenuMobile}>
          <IconHidden />
        </div>
      </div>
      <MenuMobile
        openMenuMobile={openMenuMobile}
        onCloseMenuMobile={onCloseMenuMobile}
        auth={auth}
      />
    </>
  );
};

export default Header;
