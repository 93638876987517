import IconMasterAdmin from 'src/assets/icons/SidebarIcons/icon_master_admin.svg';
import IconSchedule from 'src/assets/icons/SidebarIcons/icon_schedule.svg';
import IconInvoiceIssues from 'src/assets/icons/SidebarIcons/icon_invoice.svg';
import IconPayment from 'src/assets/icons/SidebarIcons/icon_payment.svg';
import { RoutePath } from 'src/enums/routePath';

export const MenuAdmin = [
  {
    key: RoutePath.ADMIN_SCHEDULE,
    label: 'スケジュール管理',
    icon: IconSchedule
  },
  {
    key: RoutePath.ADMIN_INVOICE_ISSUES,
    label: '請求書発行',
    icon: IconInvoiceIssues
  },
  {
    key: RoutePath.ADMIN_PAYMENT,
    label: '入金管理',
    icon: IconPayment
  },
  {
    key: RoutePath.MASTER_ADMIN,
    label: 'マスタ管理',
    icon: IconMasterAdmin,
    children: [
      {
        key: RoutePath.ADMIN_USER,
        label: '社員'
      },
      {
        key: RoutePath.ADMIN_GROUP,
        label: 'グループ'
      },
      {
        key: RoutePath.ADMIN_VEHICLE,
        label: '車両'
      },
      {
        key: RoutePath.ADMIN_CLIENT,
        label: '取引先'
      },
      {
        key: RoutePath.ADMIN_INVOICE,
        label: '請求書項目'
      }
    ]
  }
];
