import { ApiPath } from 'src/enums/apiPath';
import { IVehicle } from 'src/models/vehicle';
import { apiRequest } from 'src/utils/api';

const url = ApiPath.VEHICLE;

export const getVehicleList = async (params: IVehicle) => {
  const res = await apiRequest.get({
    url: url,
    params: params
  });

  return res.data;
};

export const postVehicleCreate = async (data: FormData) => {
  await apiRequest.post({
    url: url,
    data
  });
};

export const getVehicleDetail = async (id: number) => {
  const res = await apiRequest.get({
    url: url + `/${id}`
  });

  return res?.data;
};

export const putVehicleUpdate = async ({
  data,
  id
}: {
  data: any;
  id: number;
}) => {
  try {
    const result = await apiRequest.put({
      url: `${url}/${id}`,
      data: data
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteVehicle = async ({ id }: { id: number }) => {
  try {
    const result = await apiRequest.delete({
      url: `${url}/${id}`
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
