import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';

const LoginPage = import('../pages/Login');
const TodoPage = import('../pages/Todo');
const ForgotPasswordPage = import('../pages/ForgotPassword');
const ResetPasswordPage = import('../pages/ResetPassword');
const UserPage = import('../pages/AdminManagement/MasterAdmin/User');
const GroupPage = import('../pages/AdminManagement/MasterAdmin/Group');
const ClientPage = import('../pages/AdminManagement/MasterAdmin/Client');
const InvoicePage = import('../pages/AdminManagement/MasterAdmin/Item');
const VehiclePage = import('../pages/AdminManagement/MasterAdmin/Vehicle');
const SchedulePage = import('../pages/AdminManagement/Schedule');
const InvoiceIssuesPage = import('../pages/AdminManagement/InvoiceIssues');
const InvoiceIssuesCUDPage = import(
  '../pages/AdminManagement/InvoiceIssues/InvoiceIssuesCRUD'
);
const PaymentPage = import('../pages/AdminManagement/Payment');

export const routes: RouteI[] = [
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage)
  },
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage)
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    Component: lazy(() => ForgotPasswordPage)
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage)
  },
  {
    path: RoutePath.TODO,
    Component: lazy(() => TodoPage),
    Layout: <MainLayout />
  },
  {
    path: RoutePath.ADMIN_USER,
    Component: lazy(() => UserPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_GROUP,
    Component: lazy(() => GroupPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_CLIENT,
    Component: lazy(() => ClientPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_INVOICE,
    Component: lazy(() => InvoicePage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_VEHICLE,
    Component: lazy(() => VehiclePage),
    Layout: <MainLayout />,
    isPrivate: true
  },

  {
    path: RoutePath.ADMIN_SCHEDULE,
    Component: lazy(() => SchedulePage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_INVOICE_ISSUES,
    Component: lazy(() => InvoiceIssuesPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_INVOICE_ISSUES_CREATE,
    Component: lazy(() => InvoiceIssuesCUDPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_INVOICE_ISSUES_EDIT,
    Component: lazy(() => InvoiceIssuesCUDPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ADMIN_PAYMENT,
    Component: lazy(() => PaymentPage),
    Layout: <MainLayout />,
    isPrivate: true
  }
];
