import { ApiPath } from 'src/enums/apiPath';
import { IUser } from 'src/models/user';
import { apiRequest } from 'src/utils/api';

const url = ApiPath.USER;

export const getUserList = async (params: IUser) => {
  const res = await apiRequest.get({
    url: url,
    params: params
  });

  return res.data;
};

export const postUserCreate = async (data: FormData) => {
  await apiRequest.post({
    url: url,
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
};

export const getUserDetail = async (id: number) => {
  const res = await apiRequest.get({
    url: url + `/${id}`
  });

  return res?.data;
};

export const putUserUpdate = async ({
  data,
  id
}: {
  data: object;
  id: number;
}) => {
  try {
    const result = await apiRequest.put({
      url: `${url}/${id}`,
      data: data
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteUser = async ({ id }: { id: number }) => {
  try {
    const result = await apiRequest.delete({
      url: `${url}/${id}`
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
