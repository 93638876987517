import { createSlice } from '@reduxjs/toolkit';
import { activeViews, tabSchedule } from 'src/pages/AdminManagement/Schedule';

export interface ScheduleState {
  startTime: Date | string;
  endTime: Date | string;
  actionView: string;
  detailSchedule: {};
  statusActionTabView: boolean;
  currentTabSchedule?: string;
}

const initialState: ScheduleState = {
  actionView: activeViews.Month,
  startTime: new Date(),
  endTime: '',
  detailSchedule: {},
  statusActionTabView: false,
  currentTabSchedule: tabSchedule.All
};

export default createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setActionView: (state, action) => {
      state.actionView = action.payload;
    },

    setStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    setEndTime: (state, action) => {
      state.endTime = action.payload;
    },
    setDetailSchedule: (state, action) => {
      state.detailSchedule = action.payload;
    },
    setStatusActionTabView: (state, action) => {
      state.statusActionTabView = action.payload;
    },
    setCurrenTabSchedule: (state, action) => {
      state.currentTabSchedule = action.payload;
    }
  }
});
