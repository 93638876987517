import Cookies from 'js-cookie';
import {
  LoginFormI,
  RegisterFormI,
  ResetPasswordI,
  UpdatePasswordI
} from 'src/models/auth';
import { apiRequest } from 'src/utils/api';
import { jwtDecode } from 'jwt-decode';
import { timestampToDate } from 'src/utils/date';
import { RoutePath } from 'src/enums/routePath';
import { ApiPath } from 'src/enums/apiPath';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import { fetchInit } from './init';
import authInfoSlice from 'src/redux/authInfoSlice';
import { store } from 'src/redux/store';
import { notification } from 'core-ui';
import { notifyMessage } from 'src/constants/message';

export let isLogged = !!Cookies.get(ACCESS_TOKEN_KEY);

export const login = async (payload: LoginFormI) => {
  const res = await apiRequest.post({
    url: ApiPath.LOGIN,
    data: payload
  });

  const accessToken = res?.data?.data?.access_token;
  const decoded = jwtDecode(accessToken);
  const expiredDate = timestampToDate(Number(decoded?.exp));
  const { setAuthInfo } = authInfoSlice.actions;

  async function fetchData() {
    try {
      let data = await fetchInit();
      if (data) {
        store.dispatch(setAuthInfo(data));
      }
    } catch (error) {
      notification.error({
        message: notifyMessage?.sever_error
      });
    }
  }

  fetchData();

  if (!!accessToken) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
      expires: expiredDate
    });

    isLogged = true;
  }

  return res;
};

export const register = async (payload: RegisterFormI) => {
  return await apiRequest.post({
    url: ApiPath.REGISTER,
    data: payload
  });
};

export const logout = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  window.location.replace(RoutePath.LOGIN);
};

export const resetPassword = async (data: ResetPasswordI) => {
  return apiRequest.post({
    url: ApiPath.RESET_PASSWORD,
    data
  });
};

export const updatePassword = async (data: UpdatePasswordI) => {
  return apiRequest.put({
    url: ApiPath.UPDATE_PASSWORD,
    data
  });
};
