import { isLogged } from 'src/services/auth';
import { RoutePath } from 'src/enums/routePath';

/**
 *
 * @param location
 * @param authRole
 *
 * Just only add path parent of feature as /client (don't need create, update, detail)
 *
 * */

const matchPath = (path?: string, patterns?: string[]): boolean => {
  if (patterns && path) {
    return patterns.some((pattern) => {
      const regexPattern = new RegExp(
        `^${pattern.replace(/:[^\s/]+/g, '\\d+')}$`
      );
      return regexPattern.test(path);
    });
  }
  return false;
};

export const authMiddleware = (location: Location): boolean => {
  if (isLogged) {
    const authPath: { [key: string]: string[] } = {
      admin: [
        RoutePath.ADMIN_SCHEDULE,
        RoutePath.ADMIN_INVOICE_ISSUES,
        RoutePath.ADMIN_INVOICE_ISSUES_CREATE,
        RoutePath.ADMIN_INVOICE_ISSUES_EDIT,
        RoutePath.ADMIN_PAYMENT,
        RoutePath.ADMIN_CLIENT,
        RoutePath.ADMIN_GROUP,
        RoutePath.ADMIN_INVOICE,
        RoutePath.ADMIN_USER,
        RoutePath.ADMIN_VEHICLE
      ]
    };

    const pathname = location.pathname;
    const isMatch = matchPath(pathname, authPath.admin);
    return isMatch;
  }

  return true;
};
