export const ACTION = {
  CREATE: '新規作成',
  EDIT: '編集',
  DETAIL: '詳細',
  REGISTRATION: '登録',
  ISSUES: '発行',
  KEEP: '保存',
  CREATE_ORDERED: '受注作成',
  EDIT_ORDERED: '受注編集',
  CANCEL: 'キャンセル',
  CANCEL2: '戻る',
  CREATE_SCHEDULE: '予定登録',
  EDIT_SCHEDULE: '予定編集',
  UPDATE: '更新',
  SEARCH: '検索',
  DELETE: '削除',
  INQUIRY: '照会',
  RETURN: '戻る',
  RETURN_B: '閉じる',
  USER: '社員',
  GROUP: 'グループ',
  VEHICLE: '車両',
  CLIENT: '取引先',
  INVOICE: '請求書項目',
  SCHEDULE: 'スケジュール',
  PAYMENT: '入金管理',
  DEPOSIT: '入金',
  INVOICES_ISSUES: '請求書新規作成',
  BILL: '請求',
  DELIVERY_SLIP: '納品伝票',
  SLIP_CREATE: '納品伝票新規作成',
  QUANTITY_INPUT: '数量入力'
};
