import { ApiPath } from 'src/enums/apiPath';
import { ISchedule, ScheduleEventI } from 'src/models/schedule';
import { apiRequest } from 'src/utils/api';

const url = ApiPath.SCHEDULE;

export const getScheduleList = async (params: ISchedule) => {
  const res = await apiRequest.get({
    url: url,
    params: params
  });

  return res.data;
};

export const postScheduleCreate = async (data: ScheduleEventI) => {
  await apiRequest.post({
    url: url,
    data
  });
};

export const getScheduleDetail = async (id: number) => {
  const res = await apiRequest.get({
    url: url + `/${id}`
  });

  return res?.data;
};

export const putScheduleUpdate = async ({
  data,
  id
}: {
  data: object;
  id?: number | string;
}) => {
  try {
    const result = await apiRequest.put({
      url: `${url}/${id}`,
      data: data
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteSchedule = async ({ id }: { id: number }) => {
  try {
    const result = await apiRequest.delete({
      url: `${url}/${id}`
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
