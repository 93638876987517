/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  FORGOT_PASSWORD = '/forgot_password',
  LOGIN = '/',
  NOT_FOUND = '/not-found',
  RESET_PASSWORD = '/reset_password',
  SIGN_UP = '/register',
  TODO = '/todo',
  ADMIN_SCHEDULE = '/admin-schedule',
  ADMIN_INVOICE_ISSUES = '/admin-invoice-issues',
  ADMIN_INVOICE_ISSUES_CREATE = '/admin-invoice-issues/create',
  ADMIN_INVOICE_ISSUES_EDIT = '/admin-invoice-issues/:id/edit',
  ADMIN_PAYMENT = '/admin-payment',
  MASTER_ADMIN = '/master-admin',
  ADMIN_USER = '/master-admin-user',
  ADMIN_GROUP = '/master-admin-group',
  ADMIN_VEHICLE = '/master-admin-vehicle',
  ADMIN_CLIENT = '/master-admin-client',
  ADMIN_INVOICE = '/master-admin-invoice'
}
