/* eslint-disable no-unused-vars */
export const API_URL = 'https://nodejs-template.codeaplha.biz';

export enum ApiPath {
  USER = '/api/v1/users',
  GROUP = '/api/v1/groups',
  INVOICE = '/api/v1/invoices',
  SLIP = '/api/v1/slips',
  CLIENT = '/api/v1/clients',
  PAYMENT = '/api/v1/payments',
  PERIOD = '/api/v1/payments/by_period',
  EXPORT_PERIOD = '/api/v1/payments/export_by_period',
  EXPORT_PERIOD_WITHOUT_CLIENT = '/api/v1/payments/export_without_client',
  BY_CLIENT = '/api/v1/payments/by_clients',
  BY_PERIOD_WITHOUT_CLIENT = '/api/v1/payments/by_period_without_client',
  SCHEDULE = '/api/v1/schedules',
  MONTHLY_SUMMARY = 'api/v1/payments/export',
  LOGIN = '/api/v1/login',
  REGISTER = '/api/v1/register',
  TODO = '/api/v1/todos',
  RESET_PASSWORD = '/api/v1/reset_password',
  UPDATE_PASSWORD = '/api/v1/update_password',
  ME = '/api/v1/users/me',
  VEHICLE = '/api/v1/vehicles',
  ITEM = '/api/v1/items'
}
