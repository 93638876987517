import { ApiPath } from 'src/enums/apiPath';
import { IGroup } from 'src/models/group';
import { apiRequest } from 'src/utils/api';

const url = ApiPath.GROUP;

export const getGroupList = async (params: IGroup) => {
  const res = await apiRequest.get({
    url: url,
    params: params
  });

  return res.data;
};

export const postGroupCreate = async (data: FormData) => {
  await apiRequest.post({
    url: url,
    data
  });
};

export const getGroupDetail = async (id: number) => {
  const res = await apiRequest.get({
    url: url + `/${id}`
  });

  return res?.data;
};

export const putGroupUpdate = async ({
  data,
  id
}: {
  data: object;
  id?: number | string;
}) => {
  try {
    const result = await apiRequest.put({
      url: `${url}/${id}`,
      data: data
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteGroup = async ({ id }: { id: number }) => {
  try {
    const result = await apiRequest.delete({
      url: `${url}/${id}`
    });
    return result?.data?.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
