import DatePicker from 'react-datepicker';
import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import clsx from 'clsx';
import { Form, FormInstance } from 'core-ui';
import { IconDatePicker } from 'src/assets/icons/IconDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ja } from 'date-fns/locale';
import { typeDate } from 'src/pages/AdminManagement/Schedule';
import { dayjs } from 'core-helpers';
import { FORMAT } from 'src/constants/datetime';

interface RangePickerFieldFieldProps {
  isDisabled?: boolean;
  nameStart?: string;
  nameEnd?: string;
  form?: FormInstance;
  isCreateForm?: boolean;
  isOpenForm?: boolean;
  showTimeSelect?: boolean;
  dateFormat?: string;
}

const RangePickerField: React.FC<RangePickerFieldFieldProps> = ({
  isDisabled,
  nameStart,
  nameEnd,
  form,
  isCreateForm,
  isOpenForm,
  showTimeSelect,
  dateFormat
}) => {
  const [valueDateStart, setValueDateStart] = useState<Date>();

  const [valueDateEnd, setValueDateEnd] = useState<Date>();

  const [isOpenStart, setIsOpenStart] = useState<boolean>(false);

  const [isOpenEnd, setIsOpenEnd] = useState<boolean>(false);

  const isDisabledTime = (date: Date) => {
    const valueDate = isOpenStart ? valueDateEnd : valueDateStart;

    if (
      date.getDate() === valueDate?.getDate() &&
      date.getMonth() === valueDate?.getMonth() &&
      date.getFullYear() === valueDate?.getFullYear()
    ) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const hoursValueDate = Number(valueDate?.getHours());
      const minuteValueDate = Number(valueDate?.getMinutes());

      if (isOpenStart) {
        if (hours < hoursValueDate) {
          return true;
        } else if (hours === hoursValueDate) {
          return minutes <= minuteValueDate;
        }
      } else {
        if (hours > hoursValueDate) {
          return true;
        } else if (hours === hoursValueDate) {
          return minutes >= minuteValueDate;
        }
      }
      return false;
    }
    return true;
  };

  const onCalendarClose = (type: string) => {
    const isStart = type?.includes(typeDate.Start);
    const valueDate = isStart ? valueDateStart : valueDateEnd;
    const setValueDate = isStart ? setValueDateEnd : setValueDateStart;
    const setIsOpen = isStart ? setIsOpenStart : setIsOpenEnd;
    const setOpenOther = isStart ? setIsOpenEnd : setIsOpenStart;

    if (valueDate) {
      setOpenOther(true);
      setIsOpen(false);

      if (!isStart || (valueDateStart && !valueDateEnd)) {
        const newDate = new Date(valueDate);
        setValueDate(newDate);
      }
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const startDateValue = form?.getFieldValue(nameStart) ?? '';
    const endDateValue = form?.getFieldValue(nameEnd) ?? '';

    const startDate = dayjs(startDateValue);
    const endDate = dayjs(endDateValue);
    if (startDate.isValid() && endDate.isValid()) {
      setValueDateStart(startDate.toDate());
      setValueDateEnd(endDate.toDate());
    }
  }, [
    form?.getFieldValue(nameStart),
    form?.getFieldValue(nameEnd),
    valueDateStart,
    valueDateEnd,
    isCreateForm,
    isOpenForm,
    form,
    dateFormat
  ]);

  useEffect(() => {
    if (
      isCreateForm ||
      !isOpenForm ||
      (!isCreateForm &&
        !form?.getFieldValue(nameStart) &&
        !form?.getFieldValue(nameEnd))
    ) {
      setValueDateStart(undefined);
      setValueDateEnd(undefined);
    }
  }, [isDisabled, isOpenForm]);

  return (
    <>
      <div className="flex items-center gap-2 mt-3 w-full">
        <div
          className={clsx(
            'border-[1px] px-2 border-solid border-[#d9d9d9] flex-1 flex items-center justify-center rounded-md gap-2 text-center w-full',
            styles.datePickerField,
            {
              'bg-[#f3f3f3] cursor-no-drop': isDisabled
            }
          )}
        >
          <Form.Item
            name={nameStart}
            className={clsx('!mb-0 flex-1', styles.datePickerStart)}
          >
            <DatePicker
              onChange={(date: Date | null) => {
                date && setValueDateStart(date);
              }}
              selected={valueDateStart}
              locale={ja}
              // selected={form && form.getFieldValue(nameStart)}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat={dateFormat}
              showTimeSelect={showTimeSelect}
              className={clsx('w-full h-10', {
                'bg-[#f3f3f3] cursor-no-drop !text-[#00000040]': isDisabled
              })}
              disabled={isDisabled}
              open={isOpenEnd ? false : isOpenStart}
              onCalendarClose={() => {
                onCalendarClose(typeDate.Start);
              }}
              onCalendarOpen={() => {
                setIsOpenStart(true);
              }}
              onClickOutside={() => {
                setIsOpenStart(false);
              }}
              onBlur={() => {
                setIsOpenStart(false);
              }}
              filterTime={isDisabledTime}
              maxDate={valueDateEnd && valueDateEnd}
            />
          </Form.Item>
          <IconDatePicker />
        </div>
        <div
          className={clsx('text-xs', {
            '!text-[#00000040]': isDisabled
          })}
        >
          ~
        </div>
        <div
          className={clsx(
            'border-[1px] px-2 border-solid border-[#d9d9d9] flex-1 flex items-center justify-center rounded-md gap-2 text-center w-full',
            styles.datePickerField,
            {
              'bg-[#f3f3f3] cursor-no-drop': isDisabled
            }
          )}
        >
          <Form.Item
            name={nameEnd}
            className={clsx('!mb-0 flex-1', styles.datePickerEnd)}
          >
            <DatePicker
              onChange={(date: Date | null) => {
                date && setValueDateEnd(date);
              }}
              selected={valueDateEnd}
              // selected={form && form.getFieldValue(nameEnd)}
              locale={ja}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat={dateFormat}
              showTimeSelect={showTimeSelect}
              className={clsx('w-full h-10', {
                'bg-[#f3f3f3] cursor-no-drop !text-[#00000040]': isDisabled
              })}
              disabled={isDisabled}
              open={isOpenStart ? false : isOpenEnd}
              onCalendarClose={() => {
                onCalendarClose(typeDate.End);
              }}
              onClickOutside={() => {
                setIsOpenEnd(false);
              }}
              onBlur={() => {
                setIsOpenEnd(false);
              }}
              onCalendarOpen={() => {
                setIsOpenEnd(true);
              }}
              minDate={valueDateStart && valueDateStart}
              filterTime={isDisabledTime}
            />
          </Form.Item>
          <IconDatePicker />
        </div>
      </div>
    </>
  );
};

export default RangePickerField;
