export const IconDatePicker = ({ color }: any) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.375 2.1875H2.625C2.38338 2.1875 2.1875 2.38338 2.1875 2.625V11.375C2.1875 11.6166 2.38338 11.8125 2.625 11.8125H11.375C11.6166 11.8125 11.8125 11.6166 11.8125 11.375V2.625C11.8125 2.38338 11.6166 2.1875 11.375 2.1875Z"
        stroke="#CCCCCC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.625 1.3125V3.0625"
        stroke="#CCCCCC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.375 1.3125V3.0625"
        stroke="#CCCCCC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.1875 4.8125H11.8125"
        stroke="#CCCCCC"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
