import { combineReducers } from '@reduxjs/toolkit';
import loadingFullScreenSlice from './loadingFullScreen/loadingFullScreenSlice';
import authInfoSlice from './authInfoSlice';
import scheduleSlice from './scheduleSlice';
import widthHeightSlice from './widthHeightSlice';

const rootReducer = combineReducers({
  [authInfoSlice.name]: authInfoSlice.reducer,
  [scheduleSlice.name]: scheduleSlice.reducer,
  [widthHeightSlice.name]: widthHeightSlice.reducer,
  [loadingFullScreenSlice.name]: loadingFullScreenSlice.reducer
});

export default rootReducer;
