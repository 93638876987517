export const IconLogoMb = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9993 0C9.89316 0.000308667 7.8242 0.554828 6.00026 1.60785C4.17632 2.66087 2.66165 4.1753 1.60841 5.99901C0.55517 7.82271 0.00045682 9.89145 2.82031e-07 11.9974C-0.000456256 14.1033 0.55336 16.1723 1.60581 17.9965C2.65826 19.8207 4.17228 21.3357 5.99576 22.3896C7.81924 23.4434 9.88796 23.9988 11.9941 24C14.1002 24.0012 16.1696 23.4482 17.9943 22.3965C19.819 21.3448 21.3348 19.8315 22.3893 18.0085C23.455 16.187 24.0113 14.1124 23.9998 12.0021C24.0002 10.4261 23.69 8.86542 23.0871 7.40927C22.4842 5.95313 21.6003 4.63001 20.4859 3.51548C19.3715 2.40095 18.0484 1.51685 16.5923 0.913654C15.1362 0.310462 13.5754 -3.66832e-08 11.9993 0ZM4.66143 18.5911L5.96329 11.2482C6.42521 8.45566 7.89537 6.16388 10.4095 5.40945L9.10402 12.7705C8.56029 15.6308 7.14036 17.8201 4.66143 18.5911ZM8.82128 20.3972L10.7824 9.41359C11.2443 6.62007 12.7144 4.33036 15.2281 3.57489L13.2639 14.5766C12.7212 17.4369 11.3007 19.6256 8.82128 20.3972ZM18.0809 12.7705C17.5382 15.6308 16.1177 17.8201 13.6383 18.5911L14.9407 11.2482C15.4026 8.45566 16.8717 6.16388 19.3858 5.40945L18.0809 12.7705Z"
        fill="#4759FF"
      />
    </svg>
  );
};
