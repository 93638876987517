export const IconHidden = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2147 10.1258H22.0718C22.1897 10.1258 22.2861 10.0294 22.2861 9.91155V8.41155C22.2861 8.29369 22.1897 8.19727 22.0718 8.19727H9.2147C9.09685 8.19727 9.00042 8.29369 9.00042 8.41155V9.91155C9.00042 10.0294 9.09685 10.1258 9.2147 10.1258ZM9.00042 15.5901C9.00042 15.708 9.09685 15.8044 9.2147 15.8044H22.0718C22.1897 15.8044 22.2861 15.708 22.2861 15.5901V14.0901C22.2861 13.9723 22.1897 13.8758 22.0718 13.8758H9.2147C9.09685 13.8758 9.00042 13.9723 9.00042 14.0901V15.5901ZM22.5004 2.57227H1.50042C1.38256 2.57227 1.28613 2.66869 1.28613 2.78655V4.28655C1.28613 4.40441 1.38256 4.50084 1.50042 4.50084H22.5004C22.6183 4.50084 22.7147 4.40441 22.7147 4.28655V2.78655C22.7147 2.66869 22.6183 2.57227 22.5004 2.57227ZM22.5004 19.5008H1.50042C1.38256 19.5008 1.28613 19.5973 1.28613 19.7151V21.2151C1.28613 21.333 1.38256 21.4294 1.50042 21.4294H22.5004C22.6183 21.4294 22.7147 21.333 22.7147 21.2151V19.7151C22.7147 19.5973 22.6183 19.5008 22.5004 19.5008ZM1.3772 12.1857L5.56381 15.483C5.71917 15.6062 5.94953 15.4964 5.94953 15.2982V8.70352C5.94953 8.5053 5.72185 8.39548 5.56381 8.51869L1.3772 11.816C1.34905 11.8379 1.32627 11.8659 1.3106 11.898C1.29493 11.93 1.28679 11.9652 1.28679 12.0008C1.28679 12.0365 1.29493 12.0717 1.3106 12.1037C1.32627 12.1357 1.34905 12.1638 1.3772 12.1857Z"
        fill="white"
        fill-opacity="0.85"
      />
    </svg>
  );
};
