export const notifyMessage = {
  success: '成功!',
  failed: '失敗した!',
  sever_error: 'サーバーエラー',
  edit_success: '登録しました。',
  create_success: '登録しました。',
  create_failed: 'アカウントの登録に失敗しました。',
  delete_success: '削除しました。',
  file_error_50MB: 'ファイルは50MBより小さくなければなりません！',
  upload_file_success: 'ファイルのアップロードに成功しました',
  download_file_success: 'ファイルのダウンロードが成功しました',
  file_empty: 'ファイルアップロードは必要です。',
  must_be_at_least_1_invoice_item:
    '明細がないため、請求書を新規作成することができません。',
  least_1_item_in_the_delivery_slip:
    '明細が選択されていないため、納品書を作成することができません。'
};
