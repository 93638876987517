import React from 'react';
import CustomTimeLine from './CustomTimeLine';
import 'react-calendar-timeline/lib/Timeline.css';
import CustomToolbarViewDayWeek from '../CustomToolbarViewDayWeek';
import { ScheduleEventI } from 'src/models/schedule';
import { Employee, TypeTabScheduleAction } from '..';

interface ScheduleWeekProps {
  showDrawEdit: () => void;
  listEmployeeCharge: Employee[];
  listScheduleData: ScheduleEventI[];
  typeTabSchedule: TypeTabScheduleAction;
}

const ScheduleWeek: React.FC<ScheduleWeekProps> = ({
  showDrawEdit,
  listEmployeeCharge,
  listScheduleData,
  typeTabSchedule
}) => {
  return (
    <div className="bg-white md:mt-5 md:mx-5 xs:pt-5 mb-8">
      <CustomToolbarViewDayWeek />
      <CustomTimeLine
        showDrawEdit={showDrawEdit}
        listEmployeeCharge={listEmployeeCharge}
        listScheduleData={listScheduleData}
        typeTabSchedule={typeTabSchedule}
      />
    </div>
  );
};

export default ScheduleWeek;
