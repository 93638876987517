import { Button, Drawer, Menu } from 'core-ui';
import { IconHiddenRight } from 'src/assets/icons/IconHiddenRight';
import { IconLogoMb } from 'src/assets/icons/IconLogoMb';
import { IUser } from 'src/models/user';
import styles from './styles.module.scss';
import { menu } from '../..';
import { RoutePath } from 'src/enums/routePath';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconLogout } from 'src/assets/icons/IconLogout';
import { logout } from 'src/services/auth';

interface MenuMobileProps {
  onCloseMenuMobile: () => void;
  openMenuMobile?: boolean;
  auth?: IUser;
}

const titleMenuMobile = (
  <div className="flex justify-between">
    <IconHiddenRight />
    <div className="flex gap-2">
      <IconLogoMb />
      <span className="text-[#4759ff] text-base">瑞穂サービスシステム</span>
    </div>
  </div>
);

const MenuMobile: React.FC<MenuMobileProps> = ({
  openMenuMobile,
  onCloseMenuMobile,
  auth
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const parentPathname =
    '/' +
    location?.pathname
      .split('/')
      .filter((item, index) => index === 1)
      .join('');

  const [menuSelected, setMenuSelected] = useState(parentPathname);

  useEffect(() => {
    const result = location?.pathname
      ?.split('/')
      .slice(0, 3)
      .join('/')
      .replace(/\/\d+$/, '')
      .replace(/\/(create|edit|detail)$/, '');

    setMenuSelected(result);
  }, [menuSelected, location]);

  return (
    <>
      <div className={styles.drawerCustom}>
        <Drawer
          title={titleMenuMobile}
          placement={'left'}
          closable={false}
          onClose={onCloseMenuMobile}
          open={openMenuMobile}
          width={'75%'}
          className={styles.drawerCustom}
        >
          <div className="px-6 pb-4 font-medium text-sm text-[#1f1f1f]">
            {auth?.name}
          </div>
          <hr className="text-[#bfbfbf]" />
          <div className="h-full flex flex-col justify-between">
            <Menu
              selectedKeys={[menuSelected]}
              mode="inline"
              items={menu}
              onClick={(key: { key: string }) => {
                navigate(`${key.key}`);
                onCloseMenuMobile();
              }}
              className="mt-3"
            />
            <div className="flex justify-center ">
              <Button
                className="border-[1px] border-solid border-[#d82121]  hover:!text-[#d82121] hover:!border-[#d82121] text-[#d82121] w-[200px] h-10 rounded"
                onClick={logout}
              >
                <div className="flex">
                  <div>ログアウト</div>
                  <IconLogout />
                </div>
              </Button>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default MenuMobile;
