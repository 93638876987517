import React from 'react';
import { CloseOutlined, Button, Drawer as AntdDrawer } from 'core-ui';
import styles from './styles.module.scss';

interface DrawerProps {
  cancelButtonLabel?: string;
  children?: React.ReactElement;
  forceRender?: boolean;
  hideFooter?: boolean;
  isOpen?: boolean;
  okeButtonLabel?: string;
  buttonCustomLabel?: string;
  deleteButtonLabel?: string;
  okClassName?: string;
  buttonCustomClassName?: string;
  deleteClassName?: string;
  title: string;
  closeDrawer?: () => void;
  onCancel?: () => void;
  onOke?: () => void;
  onBtnCustom?: () => void;
  onDelete?: () => void;
  width?: number | string;
}

const Drawer = (props: DrawerProps) => {
  const {
    cancelButtonLabel,
    children,
    forceRender,
    hideFooter,
    isOpen,
    okeButtonLabel,
    title,
    okClassName,
    buttonCustomClassName,
    closeDrawer,
    onCancel,
    onOke,
    onBtnCustom,
    onDelete,
    buttonCustomLabel,
    deleteButtonLabel,
    width,
    deleteClassName
  } = props;
  return (
    <div className={styles.drawerCustom}>
      <AntdDrawer
        width={width}
        placement="right"
        onClose={closeDrawer}
        open={isOpen}
        bodyStyle={{
          padding: '0px'
        }}
        headerStyle={{
          display: 'none'
        }}
        forceRender={forceRender}
        className={styles.drawerCustom}
        style={{ zIndex: 999999999999 }}
      >
        <div className="flex flex-col h-[100%]">
          <div className="flex bg-[#4758ff] text-white justify-between h-[56px] px-[18px] items-center">
            <p className="font-[500] text-[20px]">{title}</p>

            <CloseOutlined
              style={{
                color: 'white',
                fontSize: '24px'
              }}
              onClick={closeDrawer}
            />
          </div>

          <div className="px-[16px] py-[24px] flex-1 overflow-auto">
            {children}
          </div>

          {hideFooter || (
            <div className="flex justify-center w-full h-[83px] pt-[8px] px-[16px] border-t border-t-[#ebebeb] gap-2">
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                  fontSize: '16px'
                }}
                onClick={onCancel}
                className={`rounded-sm`}
              >
                {cancelButtonLabel as string}
              </Button>
              {deleteButtonLabel && (
                <Button
                  style={{
                    width: '100%',
                    height: '40px',
                    fontSize: '16px',
                    backgroundColor: '#EF5DA8'
                  }}
                  type="primary"
                  className={`${deleteClassName} rounded-sm`}
                  onClick={onDelete}
                >
                  {deleteButtonLabel as string}
                </Button>
              )}
              {buttonCustomLabel && (
                <Button
                  style={{
                    width: '100%',
                    height: '40px',
                    fontSize: '16px'
                  }}
                  type="primary"
                  onClick={onBtnCustom}
                  className={`${buttonCustomClassName} rounded-sm`}
                >
                  {buttonCustomLabel as string}
                </Button>
              )}
              <Button
                style={{
                  width: '100%',
                  height: '40px',
                  fontSize: '16px'
                }}
                type="primary"
                className={`${okClassName} bg-[#4759ff] rounded-sm`}
                onClick={onOke}
              >
                {okeButtonLabel as string}
              </Button>
            </div>
          )}
        </div>
      </AntdDrawer>
    </div>
  );
};

export default Drawer;
