export const IconLogout = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40002 7.55999C9.71002 3.95999 11.56 2.48999 15.61 2.48999H15.74C20.21 2.48999 22 4.27999 22 8.74999V15.27C22 19.74 20.21 21.53 15.74 21.53H15.61C11.59 21.53 9.74002 20.08 9.41002 16.54"
        stroke="#D82121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 12H4.12"
        stroke="#D82121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.35 8.6499L3 11.9999L6.35 15.3499"
        stroke="#D82121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
