import { createSlice } from '@reduxjs/toolkit';

export interface widthHeightState {
  resize: {
    width?: number;
    height?: number;
  };
}

export default createSlice({
  name: 'resize',
  initialState: {
    resize: {
      width: 0,
      height: 0
    }
  },
  reducers: {
    setResize: (state, action) => {
      state.resize = action.payload;
    }
  }
});
