export const IconHiddenRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2147 10.1258H22.0718C22.1897 10.1258 22.2861 10.0294 22.2861 9.91155V8.41155C22.2861 8.29369 22.1897 8.19727 22.0718 8.19727H9.2147C9.09685 8.19727 9.00042 8.29369 9.00042 8.41155V9.91155C9.00042 10.0294 9.09685 10.1258 9.2147 10.1258ZM9.00042 15.5901C9.00042 15.708 9.09685 15.8044 9.2147 15.8044H22.0718C22.1897 15.8044 22.2861 15.708 22.2861 15.5901V14.0901C22.2861 13.9723 22.1897 13.8758 22.0718 13.8758H9.2147C9.09685 13.8758 9.00042 13.9723 9.00042 14.0901V15.5901ZM22.5004 2.57227H1.50042C1.38256 2.57227 1.28613 2.66869 1.28613 2.78655V4.28655C1.28613 4.40441 1.38256 4.50084 1.50042 4.50084H22.5004C22.6183 4.50084 22.7147 4.40441 22.7147 4.28655V2.78655C22.7147 2.66869 22.6183 2.57227 22.5004 2.57227ZM22.5004 19.5008H1.50042C1.38256 19.5008 1.28613 19.5973 1.28613 19.7151V21.2151C1.28613 21.333 1.38256 21.4294 1.50042 21.4294H22.5004C22.6183 21.4294 22.7147 21.333 22.7147 21.2151V19.7151C22.7147 19.5973 22.6183 19.5008 22.5004 19.5008ZM2.10042 15.4857L6.28703 12.1883C6.31521 12.1662 6.338 12.1379 6.35367 12.1057C6.36934 12.0734 6.37748 12.038 6.37748 12.0022C6.37748 11.9663 6.36934 11.9309 6.35367 11.8987C6.338 11.8664 6.31521 11.8382 6.28703 11.816L2.10042 8.51602C1.94506 8.3928 1.7147 8.50262 1.7147 8.70084V15.2982C1.71469 15.3429 1.72726 15.3867 1.75099 15.4247C1.77472 15.4626 1.80864 15.4931 1.84887 15.5126C1.88911 15.5322 1.93404 15.54 1.97852 15.5353C2.023 15.5305 2.06525 15.5133 2.10042 15.4857Z"
        fill="black"
        fill-opacity="0.85"
      />
    </svg>
  );
};
