import { apiRequest } from 'src/utils/api';
import { store } from 'src/redux/store';
import { logout } from 'src/services/auth';
import { ApiPath } from 'src/enums/apiPath';

const url = ApiPath.ME;

export const getMe = async () => {
  const res = await apiRequest.get({
    url: url
  });

  return res.data;
};

export const fetchInit = async () => {
  try {
    //set Auth
    const res = await getMe();
    const data = res.data;
    return data;
  } catch (err) {
    console.error(err);
    logout();
  }
};
